@use "../_styles/variables.scss" as vars;

.dice {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    transform: rotate(45deg);
    margin: 1.5rem;
    cursor: pointer;

    &[data-dice-type="single"]{
        background-color: vars.$sky-blue;
    }
    &[data-dice-type="double"]{
        background-color: vars.$earth-green;
    }
    &[data-dice-type="tripple"]{
        background-color: vars.$orange;
    }

    &.static {
        width: 3rem;
        height: 3rem;
        border: 1px solid vars.$desert-yellow;
        .number {
            font-size: 1.5rem;
        }
    }

    .number {
        transform: rotate(-45deg);
        user-select: none;
        font-size: 2rem;
        font-family: vars.$body-font;
        font-weight: bold;
    }
}