@use './components/_styles/variables.scss' as vars;
@use "./components/_styles/buttons.scss" as buttons;

.app {
  width: 100vw;
  min-height: 100vh;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  color: vars.$champagne;
  background-color: vars.$cocoa-brown;
  overflow-y: scroll;
  overflow-x: hidden;

  .content {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: 768px;
    padding: 2rem;
    
    .logo {
      padding-right: 3rem;
      margin-top: 2rem;
      img {
        width: 125px
      }
    }
    
    .title {
      font-family: vars.$title-font;
      font-size: 2rem;
      margin: 2rem 0 2rem 0;
    }
  
    .instructions {
      font-family: vars.$body-font;
      margin: 0 0 2rem 0;
      .blue { color: vars.$sky-blue; }
      .green {  color: vars.$bright-green }
      .red {  color: vars.$bright-orange }
    }
  
    .diceControls {
      width: 100%;
      display: flex;
      @include vars.flexRowCenter;
      margin-bottom: 2rem;
    }
  
    .rollDiceButton {
      @include buttons.button;
      margin-bottom: 2rem;
    }

    .successes {
      font-family: vars.$body-font;
      font-size: 1.5rem;
      padding: 0.75rem;
      margin: 0.75rem 0 2rem 0;
    }
  
    .diceResults {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: center;
      grid-template-columns: repeat(2, 1fr);
      place-items: center;
      margin-bottom: 2rem;
    }
  }
}
